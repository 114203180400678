
.landing {
    height: 100%;
    background: #30D4A0;
    background: linear-gradient(to right,#30D4A0 0,#3064d4 100%);
    min-width: 280px;
}

.landing button {
    border: 2px solid rgba(255,255,255, .7);
    border-radius: 3px;
    background: transparent;
    padding: 20px;
    color: rgba(255,255,255, .7);
    transition: all .3s;
    display: flex;
    align-items: center;
    height: 40px;
}

.landing button:hover {
    border-color: white;
    color: white;
    cursor: pointer;
}

.landing .header {
    height: 96px;
    padding: 32px 20px;
    display: flex;
    justify-content: space-between;
}

.landing .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 96px);
    padding: 10em 0 16em 0;
}

.landing .banner .spacer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing h1 {
    color: white;
    font-size: 36px;
    font-weight: 400;
}
.landing h1 b {
    font-weight: 700;
}
.landing h2 {
    color: rgba(255,255,255, .7);;
    font-size: 20px;
    font-weight: 400;
}

@media screen and (min-width: 640px) {
    .landing h1 {
        font-size: 48px;
    }
    
    .landing h2 {
        font-size: 24px;
    }

    .landing .header {
        padding: 32px 64px;
    }
}

@media screen and (min-width: 1008px) {
    .landing h1 {
        font-size: 64px;
    }

    .landing h2 {
        font-size: 28px;
    }

}
